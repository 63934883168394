import Pagination from '@mui/material/Pagination';
import Header from 'components/layouts/Header';
import Menu from 'components/layouts/Menu';
import config from 'config';
import { useEffect, useState } from 'react';
import { FaPen } from 'react-icons/fa';
import { TiTick } from 'react-icons/ti';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import { showPopup } from 'reducer/slices/popupSlice';
import gather from 'tools/gather';
import Add from './Add';

const Coins = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [countPage, setCountPage] = useState(1);

  const changeActive = async (e: any) => {
    dispatch(showLoading());
      
    const result = await gather(`${config.apiUrl}/v1/admin/coin/${e._id}`, true).put({
      ...e,
      isActive: !e.isActive,
    });

    if (result.code === 200) {
      dispatch(successAlert('Done!'));
      init();
    } else {
      dispatch(errorAlert(result.message));
    }

    dispatch(hideLoading());
  }

  const list = () => {
    const rows: React.ReactElement[] = [];

    data.forEach((e: any, i: number) => {
      rows.push(
        <tr key={i}>
          <td>{e.name}</td>
          <td>{e.symbol}</td>
          <td className='setting'>
            <span className='penEdit' onClick={() => dispatch(showPopup(<Add data={e} reload={init} coins={data} />))}><FaPen /></span>
            <span className={e.isActive ? 'active' : ''} onClick={() => changeActive(e)}><TiTick /></span>
          </td>
        </tr>
      );
    });

    return rows;
  }

  const init = async () => {
    dispatch(showLoading());
      
    const result = await gather(`${config.apiUrl}/v1/admin/coin?page=${currentPage}&limit=50`, true).get();
    if (result.code === 200) {
      setData(result.data.items.data);
      setCurrentPage(result.data.pages.current);
      setCountPage(result.data.pages.count);
    } else {
      dispatch(errorAlert(result.message));
    }

    dispatch(hideLoading());
  }

  useEffect(() => {
    init();
  }, [currentPage]);

  return (
    <>
      <Menu />
      <div className='container'>
        <Header />
        {/* <div className='topButtons'>
          <span onClick={() => dispatch(showPopup(<Add reload={init} />))}><IoIosAddCircle /> Add</span>
        </div> */}
        <table className='tableData'>
          <tbody>
            <tr>
              <td>Name</td>
              <td>Symbol</td>
              <td>Setting</td>
            </tr>
            {list()}
          </tbody>
        </table>
        <Pagination count={countPage} color="primary" onChange={(_e, v) => setCurrentPage(v)} />
      </div>
    </>
  );
}

export default Coins;
