import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import config from 'config';
import gather from 'tools/gather';

export const getData = createAsyncThunk('getData', async () => {
  return await gather(`${config.apiUrl}/v1/data`).get();
});

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    assets: [] as any,
    data: {} as any,
    market: [] as any,
    energy: [] as any,
  },
  reducers: {
    //
  }, extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
      });
  }
})

export default loadingSlice.reducer;
