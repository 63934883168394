import { configureStore } from '@reduxjs/toolkit';
import dataSlice from 'reducer/slices/dataSlice';
import alertSlice from '../slices/alertSlice';
import drawerSlice from '../slices/drawerSlice';
import loadingSlice from '../slices/loadingSlice';
import operatorSlice from '../slices/operatorSlice';
import popupSlice from '../slices/popupSlice';

const store = configureStore({
  reducer: {
    loading: loadingSlice,
    alert: alertSlice,
    drawer: drawerSlice,
    operator: operatorSlice,
    popup: popupSlice,
    data: dataSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
