import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'drawer',
  initialState: {
    visible: false,
    content: null,
    anchor: 'bottom' as any,
  },
  reducers: {
    bottomDrawer: (state, action) => {
      state.visible = true;
      state.content = action.payload;
      state.anchor = 'bottom';
    },
    topDrawer: (state, action) => {
      state.visible = true;
      state.content = action.payload;
      state.anchor = 'top';
    },
    hideDrawer: state => {
      state.visible = false;
    },
  }
})

export const { bottomDrawer, topDrawer, hideDrawer } = slice.actions;
export default slice.reducer;
