import Pagination from '@mui/material/Pagination';
import Header from 'components/layouts/Header';
import Menu from 'components/layouts/Menu';
import config from 'config';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { TiAnchor } from 'react-icons/ti';
import { useAppDispatch } from 'reducer';
import { errorAlert } from 'reducer/slices/alertSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const Callback = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const [searchLimit, setSearchLimit] = useState('');
  const [searchFromDate, setSearchFromDate] = useState('');
  const [searchToDate, setSearchToDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [clear, setClear] = useState(true);
  const [searchSearchAll, setSearchSearchAll] = useState('');

  const list = () => {
    const rows: React.ReactElement[] = [];

    data.forEach((e: any, i: number) => {
      const ver: any = [];
      Object.entries(e.data! || []).forEach((e: any, k) => {
        // if (e[0] === 'sender' || e[0] === 'hash' || e[0] === 'receiver' || e[0] === 'reference' || e[0] === 'batchId') {
        ver.push(
          <div key={k} style={{ fontSize: 12 }}>
            <span style={{ color: '#999', fontSize: 12 }}>{e[0]}</span>: {e[1]}
          </div>,
        );
        // }
      });

      rows.push(
        <tr key={i}>
          <td>{e._id}</td>
          <td>
            {e.user?.email} - {e.user?.telegram}
          </td>
          <td>{e.amount}</td>
          <td>{e.fee}</td>
          <td>{e.coin?.symbol}</td>
          <td>{e.side}</td>
          <td>{e.type}</td>
          <td className='setting'>{e.status}</td>
          <td>{e.try1}</td>
          <td>{e.try2}</td>
          <td>{moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
          <td className='setting'>
            <span className={'active'} onClick={() => {}}>
              <TiAnchor />
            </span>
          </td>
        </tr>,
      );
    });

    return rows;
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(
      `${config.apiUrl}/v1/admin/transaction?page=${currentPage}&searchAll=${searchSearchAll}&callback=true&fromDate=${Number(
        moment(searchFromDate).unix() * 1000,
      ) || ''}&toDate=${Number(moment(searchToDate).unix() * 1000) || ''}&limit=${searchLimit || 50}`,
      true,
    ).get();
    if (result.code === 200) {
      setData(result.data.items.data);
      setCurrentPage(result.data.pages.current);
      setCountPage(result.data.pages.count);
    } else {
      dispatch(errorAlert(result.message));
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, [currentPage, clear]);

  return (
    <>
      <Menu />
      <div className='container'>
        <Header />
        <div className='searchPro'>
          <input value={searchSearchAll} placeholder='Hash, Code, Ref, Sender ...' onChange={(e) => setSearchSearchAll(e.target.value)} />
          <input type='date' value={searchFromDate} onChange={(e) => setSearchFromDate(e.target.value)} />
          <input type='date' value={searchToDate} onChange={(e) => setSearchToDate(e.target.value)} />
          <select value={searchLimit} onChange={(e) => setSearchLimit(e.target.value)}>
            <option>Limit</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
            <option value='200'>200</option>
            <option value='300'>300</option>
            <option value='400'>400</option>
          </select>
          <div
            onClick={() => {
              setCurrentPage(1);
              setClear(!clear);
            }}
          >
            SEARCH
          </div>
          <div
            onClick={() => {
              setSearchLimit('');
              setSearchFromDate('');
              setSearchToDate('');
              setCurrentPage(1);
              setClear(!clear);
              setTimeout(() => {
                // init();
              }, 500);
            }}
          >
            CLEAR
          </div>
        </div>
        <table className='tableData'>
          <tbody>
            <tr>
              <td>ID</td>
              <td>User</td>
              <td>Amount</td>
              <td>Fee</td>
              <td>Coin</td>
              <td>Side</td>
              <td>Type</td>
              <td>Status</td>
              <td>Try1</td>
              <td>Try2</td>
              <td>Date</td>
              <td>Setting</td>
            </tr>
            {list()}
          </tbody>
        </table>
        <Pagination count={countPage} color='primary' onChange={(_e, v) => setCurrentPage(v)} />
      </div>
    </>
  );
};

export default Callback;
