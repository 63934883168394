import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'popup',
  initialState: {
    visible: false,
    content: null,
  },
  reducers: {
    showPopup: (state, action) => {
      state.visible = true
      state.content = action.payload
    },
    hidePopup: state => {
      state.visible = false
    },
  }
})

export const { showPopup, hidePopup } = slice.actions;
export default slice.reducer;
