import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'loading',
  initialState: {
    visible: false,
  },
  reducers: {
    showLoading: state => {
      state.visible = true
    },
    hideLoading: state => {
      state.visible = false
    },
  }
})

export const { showLoading, hideLoading } = slice.actions;
export default slice.reducer;
