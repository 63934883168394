import { Alert, Backdrop, CircularProgress, Collapse, Drawer, Fade, Modal } from '@mui/material';
import Callback from 'components/pages/Callback';
import Coins from 'components/pages/Coins';
import Dashboard from 'components/pages/Dashboard';
import Login from 'components/pages/Login';
import Operators from 'components/pages/Operators';
import Stats from 'components/pages/Stats';
import Transactions from 'components/pages/Transactions';
import Users from 'components/pages/Users';
import config from 'config';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'reducer';
import { hideAlert } from 'reducer/slices/alertSlice';
import { hideDrawer } from 'reducer/slices/drawerSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import { hidePopup } from 'reducer/slices/popupSlice';
import gather from './tools/gather';

const App = () => {
  const [isLogin, setIsLogin] = useState(false);
  const dispatch = useAppDispatch();
  const useLoading = useAppSelector((state) => state.loading);
  const useAlert = useAppSelector((state) => state.alert);
  const useDrawer = useAppSelector((state) => state.drawer);
  const usePopup = useAppSelector((state) => state.popup);

  const refreshAuth = async (refreshToken: string) => {
    const result = await gather(`${config.apiUrl}/v1/admin/operator/refreshToken`).get({
      authorization: `Bearer ${refreshToken}`,
    });

    if (result.code === 200) {
      Cookies.set('accessToken', result.data.accessToken);
    }
  };

  const init = async () => {
    const refreshToken = Cookies.get('refreshToken');
    if (refreshToken) {
      dispatch(showLoading());

      const result = await gather(`${config.apiUrl}/v1/admin/operator/refreshToken`).get({
        authorization: `Bearer ${refreshToken}`,
      });

      if (result.code === 200) {
        // await dispatch(getData());

        Cookies.set('accessToken', result.data.accessToken);
        setIsLogin(true);

        setInterval(() => {
          refreshAuth(refreshToken);
        }, 60 * 1000);
      } else {
        Cookies.set('refreshToken', '');
      }

      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (useAlert.visible) {
      setTimeout(() => {
        dispatch(hideAlert());
      }, 1500);
    }
  }, [useAlert.visible]);

  return (
    <BrowserRouter basename='/'>
      <div className='app'>
        <Drawer
          PaperProps={{ sx: { background: '#fff' }, style: { width: 360, left: 'calc(50% - 180px)' } }}
          anchor={useDrawer.anchor}
          open={useDrawer.visible}
          onClose={() => dispatch(hideDrawer())}
        >
          {useDrawer.content}
        </Drawer>
        <Modal open={usePopup.visible} onClose={() => dispatch(hidePopup())}>
          <Fade in={usePopup.visible}>
            <div>{usePopup.content}</div>
          </Fade>
        </Modal>
        <Backdrop sx={{ backgroundColor: '#000000b0', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }} open={useLoading.visible}>
          <CircularProgress color='info' />
        </Backdrop>
        <div className='alerts'>
          <Collapse unmountOnExit in={useAlert.visible}>
            <Alert variant='filled' onClose={() => dispatch(hideAlert())} severity={useAlert.severity} sx={{ width: '100%', mb: '10px' }}>
              {useAlert.text}
            </Alert>
          </Collapse>
        </div>
        {!isLogin ? (
          <Login setIsLogin={setIsLogin} />
        ) : (
          <Routes>
            <Route path='/' element={<Dashboard />} />
            <Route path='/users' element={<Users />} />
            <Route path='/operators' element={<Operators />} />
            <Route path='/transactions' element={<Transactions />} />
            <Route path='/callbacks' element={<Callback />} />
            <Route path='/coins' element={<Coins />} />
            <Route path='/stats' element={<Stats />} />
          </Routes>
        )}
      </div>
    </BrowserRouter>
  );
};

export default App;
