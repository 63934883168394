import config from 'config';
import { useState } from 'react';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import { hidePopup } from 'reducer/slices/popupSlice';
import gather from 'tools/gather';

const AddBalance = ({ data }: any) => {
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState('');
  const [amount, setAmount] = useState('');

  const submit = async () => {
    dispatch(showLoading());
    
    const result = await gather(`${config.apiUrl}/v1/admin/wallet/${data._id}/addBalance`, true).put({
      password: password,
      amount: amount,
    });

    if (result.code === 200) {
      dispatch(hidePopup());
      dispatch(successAlert('Done!'));
    } else {
      dispatch(errorAlert(result.message ?? 'Not connected to server'));
    }

    dispatch(hideLoading());
  }

  return (
    <div className='login'>
      <div className='inputLogin'>
        <span>Amount</span>
        <input type='text' onChange={(e) => setAmount(e.target.value)} />
      </div>
      <div className='inputLogin'>
        <span>Password</span>
        <input type='password' onChange={(e) => setPassword(e.target.value)} />
      </div>
      <div className='submit' onClick={() => submit()}>Submit</div>
    </div>
  );
}

export default AddBalance;
