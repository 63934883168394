import config from 'config';
import { useState } from 'react';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import { hidePopup } from 'reducer/slices/popupSlice';
import gather from 'tools/gather';

const Add = ({ data = undefined, reload, coins }: any) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState(data?.name);
  const [symbol, setSymbol] = useState(data?.symbol);
  const [price, setPrice] = useState(data?.price);
  const [precision, setPrecision] = useState(data?.precision);
  const [all, setAll] = useState<any>({
    trade: data?.trade,
    voucher: data?.voucher,
    fee: data?.fee,
    limit: data?.limit,
    swap: data?.swap,
  });

  const setSwap = (value: any, id: string) => {
    const deep = JSON.parse(JSON.stringify(all.swap));

    const index = deep.findIndex((e: any) => e.coin._id === id);
    if (index >=0) {
      deep[index] = {
        ...deep[index],
        percent: value,
      }
    } else {
      deep.push({
        coin: {
          _id: id,
        },
        percent: value,
      })
    }

    setAll((prevState: any) => ({
      ...prevState,
      swap: deep,
    }));
  }
  
  const setValue = (value: any, field1: string = '', field2: string = '', field3: string = '') => {
    if (field1 === 'limit' || field1 === 'fee') {
      setAll((prevState: any) => ({
        ...prevState,
        [field1]: {
          ...prevState[field1],
          [field2]: {
            ...prevState[field1][field2],
            [field3]: value,
          }
        }
      }));
    }

    if (field1 === 'trade' || field1 === 'voucher') {
      setAll((prevState: any) => ({
        ...prevState,
        [field1]: {
          ...prevState[field1],
          [field2]: value,
        }
      }));
    }
  }

  const swapList = () => {
    const rows: any = [];

    coins.forEach((e: any) => {
      if (e._id !== data._id) {
        const value = data.swap.find((a: any) => a.coin._id === e._id);
        
        rows.push(
          <div key={e.symbol} className='inputLogin'>
            <span>{e.symbol}</span>
            <input type='text' defaultValue={value?.percent !== undefined ? value?.percent : ''} onChange={(d) => setSwap((d.target.value) || '', e._id)} />
          </div>
        );
      }
    });

    return rows;
  }

  const submit = async () => {
    dispatch(showLoading());
    
    const result = await gather(`${config.apiUrl}/v1/admin/coin${data ? '/'+data._id : ''}`, true)[data ? 'put': 'post']({
      name: name,
      symbol: symbol,
      price: price,
      precision: precision,
      fee: all.fee,
      limit: all.limit,
      voucher: all.voucher,
      trade: all.trade,
      swap: all.swap.map((e: any) => {
        return {
          coin: e.coin._id,
          percent: e.percent,
        }
      }).filter((e: any) => e.percent !== ''),
    });

    if (result.code === 200) {
      dispatch(hidePopup());
      dispatch(successAlert('Done!'));
      reload();
    } else {
      dispatch(errorAlert(result.message ?? 'Not connected to server'));
    }

    dispatch(hideLoading());
  }

  return (
    <div className='login'>
      <div className='inputLogin'>
        <span>Name</span>
        <input type='text' defaultValue={data?.name} onChange={(e) => setName(e.target.value)} />
      </div>
      <div className='inputLogin'>
        <span>Symbol</span>
        <input type='text' defaultValue={data?.symbol} onChange={(e) => setSymbol(e.target.value)} />
      </div>
      <div className='inputLogin'>
        <span>Precision</span>
        <input type='text' defaultValue={data?.precision} onChange={(e) => setPrecision(e.target.value)} />
      </div>
      <div className='inputLogin'>
        <span>Price</span>
        <input type='text' defaultValue={data?.price} onChange={(e) => setPrice(e.target.value)} />
      </div>
      <div className='extra'>---</div>
      <div className='group'>
        <div className='inputLogin'>
          <span>Allow send</span>
          <select defaultValue={Number(data?.trade?.send)} onChange={(e) => setValue(e.target.value, 'trade', 'send')}>
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </select>
        </div>
        <div className='inputLogin'>
          <span>Allow receive</span>
          <select defaultValue={Number(data?.trade?.receive)} onChange={(e) => setValue(e.target.value, 'trade', 'receive')}>
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </select>
        </div>
      </div>
      <div className='group'>
        <div className='inputLogin'>
          <span>Create voucher</span>
          <select defaultValue={Number(data?.voucher?.create)} onChange={(e) => setValue(e.target.value, 'voucher', 'create')}>
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </select>
        </div>
        <div className='inputLogin'>
          <span>Use voucher</span>
          <select defaultValue={Number(data?.voucher?.use)} onChange={(e) => setValue(e.target.value, 'voucher', 'use')}>
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </select>
        </div>
      </div>
      <div className='extra'>---</div>
      <div className='group'>
        <div className='inputLogin'>
          <span>Send fee type</span>
          <select defaultValue={data?.fee?.send?.type} onChange={(e) => setValue(e.target.value, 'fee', 'send', 'type')}>
            <option value={'fixed'}>Fixed</option>
            <option value={'percent'}>Percent</option>
          </select>
        </div>
        <div className='inputLogin'>
          <span>Send fee value</span>
          <input type='text' defaultValue={data?.fee?.send?.amount} onChange={(e) => setValue(e.target.value, 'fee', 'send', 'amount')} />
        </div>
      </div>
      <div className='group'>
        <div className='inputLogin'>
          <span>Receive fee type</span>
          <select defaultValue={data?.fee?.receive?.type} onChange={(e) => setValue(e.target.value, 'fee', 'receive', 'type')}>
            <option value={'fixed'}>Fixed</option>
            <option value={'percent'}>Percent</option>
          </select>
        </div>
        <div className='inputLogin'>
          <span>Receive fee value</span>
          <input type='text' defaultValue={data?.fee?.receive?.amount} onChange={(e) => setValue(e.target.value, 'fee', 'receive', 'amount')} />
        </div>
      </div>
      <div className='extra'>---</div>
      <div className='group'>
        <div className='inputLogin'>
          <span>Min send limit</span>
          <input type='text' defaultValue={data?.limit?.send?.min} onChange={(e) => setValue(e.target.value, 'limit', 'send', 'min')} />
        </div>
        <div className='inputLogin'>
          <span>Max send limit</span>
          <input type='text' defaultValue={data?.limit?.send?.max} onChange={(e) => setValue(e.target.value, 'limit', 'send', 'max')} />
        </div>
      </div>
      <div className='extra'>---</div>
      <div className='group'>
        {swapList()}
      </div>
      <div className='submit' onClick={() => submit()}>Submit</div>
    </div>
  );
}

export default Add;
