import config from 'config';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const title = config.menu.find(e => e.url === location.pathname);
  
  return (
    <div className='header'>
      <h1>{title?.title}</h1>
    </div>
  );
}

export default Header;
