import moment from 'moment';

const Check = ({ data }: any) => {
  return (
    <div className='login'>
      <div className='inner'>
        <span>BatchId</span>
        <span>{data.batchId}</span>
      </div>
      <div className='inner'>
        <span>Sender</span>
        <span>{data.sender}</span>
      </div>
      <div className='inner'>
        <span>Create Date</span>
        <span>{moment(Number(data.createdAt)).format('YYYY-MM-DD HH:mm:ss')}</span>
      </div>
      <div className='inner'>
        <span>Receiver</span>
        <span>{data.receiver || '-'}</span>
      </div>
      <div className='inner'>
        <span>Active Date</span>
        <span>{data.receiver ? moment(Number(data.activeAt)).format('YYYY-MM-DD HH:mm:ss') : '-'}</span>
      </div>
    </div>
  );
};

export default Check;
