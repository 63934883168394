import Header from 'components/layouts/Header';
import Menu from 'components/layouts/Menu';
import config from 'config';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { TiCancel } from 'react-icons/ti';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import { showPopup } from 'reducer/slices/popupSlice';
import gather from 'tools/gather';
import Send from './Send';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const [block, setBlock] = useState<any>();
  const [network, setNetwork] = useState<any>();
  const [coins, setCoins] = useState<any>([]);
  const [single, setSingle] = useState('all');
  const [data, setData] = useState<any>({});
  const [process, setProcess] = useState<any>([]);
  const [bal, setBal] = useState<any>([]);
  const [resource, setResource] = useState<any>([]);

  const listHistory = () => {
    const rows: React.ReactElement[] = [];

    data?.history?.forEach((e: any, i: number) => {
      if (single === 'UUSD' || single === 'CRP') {
        rows.push(
          <tr key={i}>
            <td>{e.type}</td>
            <td>{e.amount}</td>
            <td>
              {e.details}
              <br />
              Hash: {e.hash}
              <br />
              Ref: {e.referenceNumber}
            </td>
            <td>{e.state}</td>
            <td>{e.created}</td>
          </tr>,
        );
      } else if (
        single === 'USDC' ||
        single === 'USDT' ||
        single === 'TRX' ||
        single === 'USDT-BSC' ||
        single === 'BUSD' ||
        single === 'BNB' ||
        single === 'HotVoucher'
      ) {
        rows.push(
          <tr key={i}>
            <td>{e.type}</td>
            <td>{e.amount}</td>
            <td>{e.hash}</td>
            <td>
              {e.to}
              <br />
              {e.from}
            </td>
            <td>{e.date}</td>
          </tr>,
        );
      } else if (single === 'PM' || single === 'PME') {
        rows.push(
          <tr key={i}>
            <td>{e.amount}</td>
            <td>{e.code}</td>
            <td>{e.number}</td>
            <td>{e.batch}</td>
            <td>{e.payer}</td>
            <td>{e.date}</td>
            <td>{e.activated.toString()}</td>
            <td>{e.payee}</td>
          </tr>,
        );
      } else {
        rows.push(
          <tr key={i}>
            <td>{e.VoucherAmount}</td>
            <td>{e.CVCode}</td>
            <td>{e.CVNumber}</td>
            <td>{e.CreateDate + ' ' + e.CreateTime}</td>
            <td>{e.Activated.toString()}</td>
          </tr>,
        );
      }
    });

    return rows;
  };

  const rescan = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/admin/rescan`, true).post({
      network: network,
      block: block,
    });

    if (result.code === 200) {
      dispatch(successAlert('DONE'));
      setBlock('');
    } else {
      dispatch(errorAlert(result.message));
    }

    dispatch(hideLoading());
  };

  const sendToPool = async (address: string, coin: string) => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/admin/sendToPool`, true).post({
      address: address,
      coin: coin,
    });

    if (result.code === 200) {
      dispatch(successAlert('DONE'));
    } else {
      dispatch(errorAlert(result.message));
    }

    dispatch(hideLoading());
  };

  const sendFromNode = async (ip: string, balance: number) => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/admin/sendFromNode`, true).post({
      ip: ip,
      balance: balance,
    });

    if (result.code === 200) {
      dispatch(successAlert('DONE'));
    } else {
      dispatch(errorAlert(result.message));
    }

    dispatch(hideLoading());
  };

  const getResource = async (address: string) => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/admin/resource`, true).post({
      address: address,
    });

    if (result.code === 200) {
      setResource((prevState: any) => ({
        ...prevState,
        [address]: result.data,
      }));
    } else {
      dispatch(errorAlert(result.message));
    }

    dispatch(hideLoading());
  };

  const listExtra = () => {
    const rows: React.ReactElement[] = [];

    data?.extra?.list?.forEach((e: any, i: number) => {
      if (
        single === 'USDC' ||
        single === 'USDT' ||
        single === 'TRX' ||
        single === 'BNB' ||
        single === 'BUSD' ||
        single === 'USDT-BSC' ||
        single === 'HotVoucher'
      ) {
        rows.push(
          <tr key={i}>
            <td>{e.address}</td>
            <td>{e.balance}</td>
            <td>
              <span className='sendTo' onClick={() => sendToPool(e.address, single)}>
                Send To Pool
              </span>
              {(single === 'USDT' || single === 'TRX' || single === 'HotVoucher') && (
                <>
                  <span className='sendTo' onClick={() => getResource(e.address)}>
                    Get Resource
                  </span>
                  {resource[e.address] && (
                    <span className='showEnergy'>
                      TE: {resource[e.address].energy.total} - AE: {resource[e.address].energy.free} - TB: {resource[e.address].bandwidth.total} - AB:{' '}
                      {resource[e.address].bandwidth.free}
                    </span>
                  )}
                </>
              )}
            </td>
          </tr>,
        );
      } else {
      }
    });

    return rows;
  };

  const specialExtra = (coin: string) => {
    const rows: React.ReactElement[] = [];

    data?.extra?.special?.[coin]?.forEach((e: any, i: number) => {
      rows.push(
        <tr key={i}>
          <td>{coin.toUpperCase()}</td>
          <td>{e.address}</td>
          <td>{e.balance}</td>
          <td>
            <span className='sendTo' onClick={() => sendToPool(e.address, coin.toUpperCase())}>
              Send To Pool
            </span>
            <span className='sendTo' onClick={() => getResource(e.address)}>
              Get Resource
            </span>
            {resource[e.address] && (
              <span className='showEnergy'>
                TE: {resource[e.address].energy.total} - AE: {resource[e.address].energy.free} - TB: {resource[e.address].bandwidth.total} - AB:{' '}
                {resource[e.address].bandwidth.free}
              </span>
            )}
          </td>
        </tr>,
      );
    });

    return rows;
  };

  const listAllCoin = () => {
    const rows: React.ReactElement[] = [];

    data?.coins
      ?.sort((a: any, b: any) => a.coin.symbol.localeCompare(b.coin.symbol))
      .forEach((e: any, i: number) => {
        rows.push(
          <tr key={i}>
            <td>{e.coin.symbol}</td>
            <td>{e.sum}</td>
            <td>{bal[e.coin.symbol]}</td>
          </tr>,
        );
      });

    return rows;
  };

  const listNode = () => {
    const rows: React.ReactElement[] = [];

    data?.nodes?.forEach((e: any, i: number) => {
      rows.push(
        <tr key={i}>
          <td>{e.ip}</td>
          <td>{e.balance}</td>
          <td>
            <span className='sendTo' onClick={() => sendFromNode(e.ip, e.balance)}>
              Send Balance
            </span>
          </td>
        </tr>,
      );
    });

    return rows;
  };

  const listCoins = () => {
    const rows: React.ReactElement[] = [];

    rows.push(
      <span
        key={9000}
        className={single === 'all' ? 'active' : ''}
        onClick={() => {
          setData({});
          setSingle('all');
        }}
      >
        All
      </span>,
    );

    coins.forEach((e: any, i: number) => {
      rows.push(
        <span
          key={i}
          className={single === e.symbol ? 'active' : ''}
          onClick={() => {
            setData({});
            setSingle(e.symbol);
          }}
        >
          {e.symbol}
        </span>,
      );
    });

    return rows;
  };

  const reclaim = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/admin/reclaim`, true).get();
    if (result.code === 200) {
      dispatch(successAlert(result.message));
    } else {
      dispatch(errorAlert(result.message));
    }

    dispatch(hideLoading());
  };

  const recheck = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/admin/transaction/recheck`, true).get();
    if (result.code === 200) {
      dispatch(successAlert(result.message));
    } else {
      dispatch(errorAlert(result.message));
    }

    dispatch(hideLoading());
  };

  const getStat = async () => {
    dispatch(showLoading());

    if (single === `all`) {
      await initProcess();
    }

    const link = single === 'all' ? `allStat` : `stat?coin=${single}`;
    const result = await gather(`${config.apiUrl}/v1/admin/${link}`, true).get();
    if (result.code === 200) {
      setData(result.data);
    } else {
      dispatch(errorAlert(result.message));
    }

    dispatch(hideLoading());
  };

  const initProcess = async () => {
    const result = await gather(`${config.apiUrl}/v1/admin/transaction?status=processing&limit=500`, true).get();
    const result0 = await gather(`${config.apiUrl}/v1/admin/transaction?status=pending&limit=500`, true).get();
    if (result.code === 200 && result0.code === 200) {
      setProcess(result.data.items.data.concat(result0.data.items.data));
    }

    const result1 = await gather(`${config.apiUrl}/v1/admin/data`, true).get();
    if (result1.code === 200) {
      setBal(result1.data.items.data[0].balance);
    }
  };

  const acceptReject = async (id: string, status: string) => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/admin/transaction/${id}`, true).put({
      status: status,
    });

    if (result.code === 200) {
      dispatch(errorAlert('DONE'));
    } else {
      dispatch(errorAlert(result.message));
    }

    dispatch(hideLoading());
  };

  const listProcess = () => {
    const rows: React.ReactElement[] = [];

    process.forEach((e: any, i: number) => {
      rows.push(
        <tr key={i}>
          <td>{e._id}</td>
          <td>
            {e.user?.email} - {e.user?.telegram}
          </td>
          <td>{e.amount}</td>
          <td>{e.fee}</td>
          <td>{e.coin?.symbol}</td>
          <td>{e.side}</td>
          <td>{e.type}</td>
          <td>{e.status}</td>
          <td className='setting'>
            <span style={{ backgroundColor: '#f92b2b' }} onClick={() => acceptReject(e._id, 'reject')}>
              <TiCancel color='white' />
            </span>
          </td>
          <td>{moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
        </tr>,
      );
    });

    return rows;
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/coin`).get();
    if (result.code === 200) {
      setCoins(result.data);
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    if (single) {
      getStat();
    }
  }, [single]);

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Menu />
      <div className='container'>
        <Header />
        <div className='topButtons topCoins'>{listCoins()}</div>
        {single === 'all' ? (
          <>
            <div className='buttons'>
              <span className='sendTo' onClick={() => reclaim()}>
                Reclaim All Energy
              </span>
              <span className='sendTo' onClick={() => recheck()}>
                Recheck Processing
              </span>
            </div>

            <table className='tableData'>
              <tbody>
                <tr>
                  <td>NODE</td>
                  <td>Balance</td>
                  <td>Setting</td>
                </tr>
                {listNode()}
              </tbody>
            </table>

            <table className='tableData'>
              <tbody>
                <tr>
                  <td>Coin</td>
                  <td>Balance</td>
                  <td>Our Wallet</td>
                </tr>
                {listAllCoin()}
              </tbody>
            </table>
            <table className='tableData'>
              <tbody>
                <tr>
                  <td>Energy</td>
                  <td>Bandwidth</td>
                </tr>
                <tr>
                  <td>{data?.source?.energy}</td>
                  <td>{data?.source?.bandwidth}</td>
                </tr>
              </tbody>
            </table>

            <table className='tableData'>
              <tbody>
                <tr>
                  <td>ID</td>
                  <td>User</td>
                  <td>Amount</td>
                  <td>Fee</td>
                  <td>Coin</td>
                  <td>Side</td>
                  <td>Type</td>
                  <td>Status</td>
                  <td>Date</td>
                  <td>Setting</td>
                </tr>
                {listProcess()}
              </tbody>
            </table>

            <table className='tableData'>
              <tbody>
                <tr>
                  <td>Tron Network</td>
                  <td>Our Block</td>
                  <td>Diff</td>
                </tr>
                <tr>
                  <td>{data?.node?.network}</td>
                  <td>{data?.node?.our}</td>
                  <td>{data?.node?.network - data?.node?.our}</td>
                </tr>
              </tbody>
            </table>
            <div className='searchPro'>
              <input value={block} placeholder='Block Number' onChange={(e) => setBlock(e.target.value)} />
              <select value={network} onChange={(e) => setNetwork(e.target.value)}>
                <option>NETWORK</option>
                <option value='tron'>tron</option>
                <option value='utopia'>utopia</option>
                <option value='bsc'>bsc</option>
              </select>

              <div onClick={() => rescan()}>RESCAN</div>
            </div>
          </>
        ) : (
          single && (
            <>
              <table className='tableData'>
                <tbody>
                  <tr>
                    <td>Current Balance</td>
                    {data?.extra?.list && <td>Wallet Balance</td>}
                    {(single === 'USDC' ||
                      single === 'BUSD' ||
                      single === 'USDT-BSC' ||
                      single === 'BNB' ||
                      single === 'USDT' ||
                      single === 'TRX' ||
                      single === 'HotVoucher') && <td>Setting</td>}
                  </tr>
                  <tr>
                    <td>{data.balance}</td>
                    {data?.extra?.list && <td>{data?.extra?.balance}</td>}
                    {(single === 'USDC' ||
                      single === 'BUSD' ||
                      single === 'USDT-BSC' ||
                      single === 'BNB' ||
                      single === 'USDT' ||
                      single === 'TRX' ||
                      single === 'HotVoucher') && (
                      <td>
                        <span className='sendTo' onClick={() => dispatch(showPopup(<Send coin={single} />))}>
                          Send To Address
                        </span>
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
              {data?.extra?.list && (
                <table className='tableData'>
                  <tbody>
                    {single === 'USDC' ||
                    single === 'BUSD' ||
                    single === 'USDT-BSC' ||
                    single === 'BNB' ||
                    single === 'USDT' ||
                    single === 'TRX' ||
                    single === 'HotVoucher' ? (
                      <tr>
                        <td>Address</td>
                        <td>Balance</td>
                        <td>Send</td>
                      </tr>
                    ) : (
                      ''
                    )}
                    {listExtra()}
                  </tbody>
                </table>
              )}
              {data?.extra?.special && (
                <table className='tableData'>
                  <tbody>
                    <tr>
                      <td>Coin</td>
                      <td>Address</td>
                      <td>Balance</td>
                      <td>Send</td>
                    </tr>
                    {specialExtra('trx')}
                    {specialExtra('usdt')}
                    {specialExtra('bnb')}
                    {specialExtra('usdt-bsc')}
                    {specialExtra('busd')}
                  </tbody>
                </table>
              )}
              <table className='tableData'>
                <tbody>
                  {single === 'UUSD' || single === 'CRP' ? (
                    <tr>
                      <td>Type</td>
                      <td>Amount</td>
                      <td>Details</td>
                      <td>State</td>
                      <td>Date</td>
                    </tr>
                  ) : single === 'USDC' ||
                    single === 'BUSD' ||
                    single === 'USDT-BSC' ||
                    single === 'BNB' ||
                    single === 'USDT' ||
                    single === 'TRX' ||
                    single === 'HotVoucher' ? (
                    <tr>
                      <td>Type</td>
                      <td>Amount</td>
                      <td>Hash</td>
                      <td>From / To</td>
                      <td>Date</td>
                    </tr>
                  ) : single === 'PM' || single === 'PME' ? (
                    <tr>
                      <td>Amount</td>
                      <td>Code</td>
                      <td>Number</td>
                      <td>Batch</td>
                      <td>Payer</td>
                      <td>Date</td>
                      <td>Activated</td>
                      <td>Payee</td>
                    </tr>
                  ) : (
                    <tr>
                      <td>Amount</td>
                      <td>Code</td>
                      <td>Number</td>
                      <td>Date</td>
                      <td>Activated</td>
                    </tr>
                  )}
                  {listHistory()}
                </tbody>
              </table>
            </>
          )
        )}
      </div>
    </>
  );
};

export default Dashboard;
