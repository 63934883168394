import { FaAlipay, FaCalculator, FaChartArea, FaCoins, FaDatabase, FaPen, FaUser } from 'react-icons/fa';

const config = {
  apiUrl: process.env.REACT_APP_API_URL!,
  menu: [
    {
      title: 'Dashboard',
      url: '/',
      icon: <FaChartArea />,
    },
    {
      title: 'Coins',
      url: '/coins',
      icon: <FaCoins />,
    },
    {
      title: 'Users',
      url: '/users',
      icon: <FaUser />,
    },
    {
      title: 'Operators',
      url: '/operators',
      icon: <FaPen />,
    },
    {
      title: 'Transactions',
      url: '/transactions',
      icon: <FaDatabase />,
    },
    {
      title: 'Callback',
      url: '/callbacks',
      icon: <FaCalculator />,
    },
    {
      title: 'Stats',
      url: '/stats',
      icon: <FaAlipay />,
    },
  ],
};

export default config;
