import config from 'config';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useAppDispatch } from 'reducer';
import { errorAlert, successAlert } from 'reducer/slices/alertSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const Login = ({ setIsLogin }: any) => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const start = async () => {
    dispatch(showLoading());
      
    const result = await gather(`${config.apiUrl}/v1/admin/operator/login`).post({
      email: email,
      password: password,
    });

    if (result.code === 200) {
      Cookies.set('refreshToken', result.data.refreshToken);
      Cookies.set('accessToken', result.data.accessToken);
      setIsLogin(true);
      dispatch(successAlert('Login successful'));
    } else {
      dispatch(errorAlert(result.message ?? 'Not connected to server'));
    }

    dispatch(hideLoading());
  }

  return (
    <div className='login'>
      <h3>Enter Your Email And Password</h3>
      <div className='inputLogin'>
        <span>Email</span>
        <input type='text' onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div className='inputLogin'>
        <span>Password</span>
        <input type='password' onChange={(e) => setPassword(e.target.value)} />
      </div>
      <div className='submit' onClick={() => start()}>Login</div>
    </div>
  );
}

export default Login;
