import Pagination from '@mui/material/Pagination';
import Header from 'components/layouts/Header';
import Menu from 'components/layouts/Menu';
import config from 'config';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'reducer';
import { errorAlert } from 'reducer/slices/alertSlice';
import { hideLoading, showLoading } from 'reducer/slices/loadingSlice';
import gather from 'tools/gather';

const Stats = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [countPage, setCountPage] = useState(1);

  const list = () => {
    const rows: React.ReactElement[] = [];

    data.forEach((e: any, i: number) => {
      console.log(e.block);
      console.log(e.trx);
      console.log(e.usdt);

      const userBalance: any = [];
      e.block.coins.map((e: any) => {
        userBalance.push(
          <div>
            {e.coin.symbol}: {e.sum}
          </div>,
        );
      });

      const usdtPending: any = [];
      e.usdt.extra.list.map((e: any) => {
        usdtPending.push(
          <div>
            {e.address} === {e.balance}
          </div>,
        );
      });

      const trxPending: any = [];
      e.trx.extra.list.map((e: any) => {
        trxPending.push(
          <div>
            {e.address} === {e.balance}
          </div>,
        );
      });

      rows.push(
        <tr key={i} className='allStat'>
          <td>
            <i className='titleStat'>Block:</i>
            {e.block.node.our}
            <i className='titleStat'>User Balances:</i>
            {userBalance}
            <i className='titleStat'>USDT Balance:</i>
            {e.usdt.balance}
            <i className='titleStat'>USDT Pending Balance:</i>
            {e.usdt.extra.balance}
            <i className='titleStat'>USDT Pending List:</i>
            {usdtPending.length ? usdtPending : 'NONE'}
            <i className='titleStat'>TRX Balance:</i>
            {e.trx.balance}
            <i className='titleStat'>TRX Pending Balance:</i>
            {e.trx.extra.balance}
            <i className='titleStat'>TRX Pending List:</i>
            {trxPending.length ? trxPending : 'NONE'}
          </td>
          <td>{moment(e.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
        </tr>,
      );
    });

    return rows;
  };

  const init = async () => {
    dispatch(showLoading());

    const result = await gather(`${config.apiUrl}/v1/admin/history?page=${currentPage}&limit=50`, true).get();
    if (result.code === 200) {
      setData(result.data.items.data);
      setCurrentPage(result.data.pages.current);
      setCountPage(result.data.pages.count);
    } else {
      dispatch(errorAlert(result.message));
    }

    dispatch(hideLoading());
  };

  useEffect(() => {
    init();
  }, [currentPage]);

  return (
    <>
      <Menu />
      <div className='container'>
        <Header />
        <table className='tableData'>
          <tbody>
            <tr>
              <td>Data</td>
              <td>Date</td>
            </tr>
            {list()}
          </tbody>
        </table>
        <Pagination count={countPage} color='primary' onChange={(_e, v) => setCurrentPage(v)} />
      </div>
    </>
  );
};

export default Stats;
